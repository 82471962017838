/* body {
    background-color: rgb(214, 214, 220);
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
} */


/* body::-webkit-scrollbar {
    display: none;
} */

.app {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
}

img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    background-size: "contain";
    object-fit: "cover";
}